import "./index.scss";
import { useState, useEffect } from "react";
import { I18n } from "i18n-js";
import translations from "../../config/locales.json";

const Header = (props) => {
  const i18n = new I18n(translations);
  i18n.locale = props.languageSlug;
  const [account, setAccount] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountChanged);
      window.ethereum.on("chainChanged", chainChanged);
    }
    else {
      alert(i18n.t("basic.mission5.installMetamask"));
    }
  }, []);

  const handleConnectWallet = async () => {
    try {
      const res = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      await accountChanged(res[0]);
      await checkNetwork();
    }
    catch (e) {
      console.error(e);
      alert(e.message);
    }
  }

  const chainChanged = async () => {
    try {
      await checkNetwork();
      setErrorMsg()
    }
    catch (e) {
      console.error(e);
      setErrorMsg(e.message)
    }
  }

  const checkNetwork = async () => {
    const chainId = 0x13881; // mumbai Id
    const currentChainId = await window.ethereum.request({
      method: 'eth_chainId',
    });

    if (currentChainId != chainId) {
      throw Error(i18n.t("basic.mission5.wrongChain"));
    }
  }

  const accountChanged = async (newAccount) => {
    setAccount(newAccount);
    document.getElementById("connect-wallet").style.display = "none";
    document.getElementById("address").style.display = "block";
  }

  return (
    <div className="header">
      <button id="connect-wallet" onClick={handleConnectWallet} className="connectWallet">{i18n.t("basic.mission5.connectWallet")}</button>
      <div className="wrapper">
        <h1>{i18n.t("basic.mission5.title")}</h1>
        <p id="address" className="address">{account}</p>
      </div>
      <h1>{errorMsg}</h1>
    </div>
  )
}

export default Header

import React from "react";
import logo from "./logo.png";
import "./index.scss";
import { useState } from "react";
import base58 from "bs58";
import { I18n } from "i18n-js";
import translations from "../../config/locales.json";

export default function SolanaMission5(props) {
  const i18n = new I18n(translations, props.languageSlug);
  i18n.locale = props.languageSlug;

  const getProvider = () => {
    if ("phantom" in window) {
      const provider = window.phantom?.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }

    window.open("https://phantom.app/", "_blank");
  };

  const [connected, setConnected] = useState(false);
  const [publicKey, setPublicKey] = useState("");
  const [signature, setSignature] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess(i18n.t("solana.mission5.copySuccess"));
      document.getElementById("clipboard").style.display = "block";
    } catch (err) {
      setCopySuccess(i18n.t("solana.mission5.copyFail"));
    }
  };

  const handleConnect = async () => {
    const provider = getProvider();
    try {
      document.getElementById("connect-wallet").style.display = "none";
      const res = await provider.request({ method: "connect" });
      console.log(res.publicKey.toString());
      setPublicKey(res.publicKey.toString());
      setConnected(true);
      document.getElementById("address").style.display = "block";
    } catch (err) {
      console.log(err);
    }
  };

  const handleSign = async () => {
    const provider = getProvider();
    const message = "Melk solana trail.";
    const encodedMessage = new TextEncoder().encode(message);
    const signedMessage = await provider.request({
      method: "signMessage",
      params: {
        message: encodedMessage,
        display: "utf8",
      },
    });

    const hash = base58.encode(signedMessage.signature);
    setSignature(hash);
  };
  return (
    <div className="App">
      <nav>
        <img src={logo} className="App-logo" alt="logo" />
        <a>
          <h1 className="nav-text">Website</h1>
        </a>
        <a href={i18n.t("solana.mission5.docs-url")} target="_blank" rel="noreferrer">
          <h1 className="nav-text">Docs</h1>
        </a>
        <p className="address" id="address" >{publicKey}</p>
        <button id="connect-wallet" onClick={handleConnect} className="connectWallet">{i18n.t("solana.mission5.connect")}</button>
      </nav>
      <div className="res">
        <h1>{i18n.t("solana.mission5.title")}</h1>
        <p className="description">{i18n.t("solana.mission5.description")}</p>
        {connected ? (
          <>
            {signature && (
              <div className="signature">
                <h1 className="results">{i18n.t("solana.mission5.signature")}</h1>
                <textarea
                  onClick={() => copyToClipBoard(signature)}
                  autoFocus
                  readOnly
                  value={signature}
                >
                  {signature}
                </textarea>
                <span style={{ color: "black" }} className="clipboard" id="clipboard">
                  {copySuccess}
                </span>
              </div>
            )}
          </>
        ) : (
          <div>
            <button className="signButton" onClick={handleConnect}>
              {i18n.t("solana.mission5.button")}
            </button>
          </div>
        )}
        {connected && !signature && (
          <div>
            <button className="signButton" onClick={handleSign}>
              {i18n.t("solana.mission5.button")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

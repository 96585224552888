import { Link } from "react-router-dom";
import "./App.scss";
import { I18n } from "i18n-js";
import translations from "./config/locales.json";

function App(props) {
  const i18n = new I18n(translations, props.languageSlug);
  i18n.defaultLocale = "en";
  i18n.locale = props.languageSlug;

  let redirectBasic = `basic/mission-5`;
  let redirectSolana = `solana/mission-5`;

  return (
    <div className="App">
      <div className="route-redirect">
        <h2>{i18n.t("redirectText")}</h2>
        <div>
          <Link className="redirect-link" to={redirectBasic}>
            {i18n.t("redirectLinks.basic.mission5")}
          </Link>
          <Link className="redirect-link" to={redirectSolana}>
            {i18n.t("redirectLinks.solana.mission5")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;

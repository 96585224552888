import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Redirect from "./Redirect";
import reportWebVitals from "./reportWebVitals";
import Mission5 from "./basic/mission-5";
import SolanaMission5 from "./solana/mission-5";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Redirect languageSlug="en" />} />
        <Route path="/en/" element={<App languageSlug="en" />} />
        <Route path="/pt-br/" element={<App languageSlug="pt-BR" />} />
        <Route
          path="/pt-br/basic/mission-5"
          element={<Mission5 languageSlug="pt-BR" />}
        />
        <Route
          path="/pt-br/solana/mission-5"
          element={<SolanaMission5 languageSlug="pt-BR" />}
        />
        <Route
          path="/en/basic/mission-5"
          element={<Mission5 languageSlug="en" />}
        />
        <Route
          path="/en/solana/mission-5"
          element={<SolanaMission5 languageSlug="en" />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

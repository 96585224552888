import { Link } from "react-router-dom";
import "./App.scss";
import { I18n } from "i18n-js";
import translations from "./config/locales.json";

function Redirect(props) {
  const i18n = new I18n(translations, props.languageSlug);
  i18n.defaultLocale = "en";
  i18n.locale = props.languageSlug;

  let locales = Object.keys(translations).map(locale => locale.toLowerCase());

  return (
    <div className="App">
      <div className="route-redirect">
        <h2>Choose your language of preference.</h2>
        <div>
          {locales.map(locale => (
            <Link className="redirect-link" to={locale}>
              {locale}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Redirect;

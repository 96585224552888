import { useState } from "react";
import { ethers } from "ethers";
import "../../index.scss";
import { I18n } from "i18n-js";
import translations from "../../../../config/locales.json";

const signMessage = async ({ setError, message }) => {
  try {
    console.log({ message });
    if (!window.ethereum)
      throw new Error("No crypto wallet found. Please install it.");

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();

    return {
      message,
      signature,
      address,
    };
  } catch (err) {
    if (err.code == "ACTION_REJECTED") {
      setError(
        "Você recusou a assinatura. Por favor tente novamente e clique em Assinar."
      );
    } else {
      setError(err.message);
    }
  }
};

export default function SignMessage(props) {
  const i18n = new I18n(translations, props.languageSlug);
  i18n.locale = props.languageSlug;

  const [signatures, setSignatures] = useState([]);
  const [error, setError] = useState();
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess(i18n.t("basic.mission5.copySuccess"));
      document.getElementById("clipboard").style.display = "block";
    } catch (err) {
      setCopySuccess(i18n.t("basic.mission5.copyFail"));
    }
  };

  const handleSign = async (e) => {
    setError();
    const sig = await signMessage({
      setError,
      message: "Experimento MELK",
    });
    if (sig) {
      setSignatures([...signatures, sig]);
    } else {
      clearText();
      return <h1>{error}</h1>;
    }
    clearScreen();
  };

  function clearScreen() {
    document.getElementById("explanation").style.display = "none";
    document.getElementById("button").style.display = "none";
  }

  function clearText() {
    document.getElementById("explanation").style.display = "none";
  }

  return (
    <form className="form">
      {error && <h1 class="alert-error">{error}</h1>}
      <button
        type="button"
        className="sign-button"
        id="button"
        onClick={handleSign}
      >
        {i18n.t("basic.mission5.button-text")}
      </button>
      {signatures.map((sig) => {
        return (
          <div className="signature-result">
            <p className="result-text">
              {i18n.t("basic.mission5.message")} <strong>{sig.message}</strong>
            </p>
            <p className="result-text">
              {i18n.t("basic.mission5.signing-address")} <strong>{sig.address}</strong>
            </p>
            <p className="result-text">{i18n.t("basic.mission5.result-hash")} </p>
            <textarea
              style={{ marginTop: "1rem" }}
              onClick={() => copyToClipBoard(sig.signature)}
              autoFocus
              readOnly
            >
              {sig.signature}
            </textarea>
            <span className="clipboard" id="clipboard">
              {copySuccess}
            </span>
            <p className="result-text">
              {i18n.t("basic.mission5.result-text")}
            </p>
          </div>
        );
      })}
    </form>
  );
}

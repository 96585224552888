import "./index.scss";
import { I18n } from "i18n-js";
import translations from "../../config/locales.json"

export default function Explanation(props) {
  const i18n = new I18n(translations);
  i18n.locale = props.languageSlug;

  return (
    <div className="explanation" id="explanation">
      <p>{i18n.t("basic.mission5.explanation")}</p>
      <p><a target="_blank" rel="noreferrer" href={i18n.t("basic.mission5.docs-url")}>{i18n.t("basic.mission5.docs-info")}</a></p>
    </div >
  )
}

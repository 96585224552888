import React from "react";
import "./index.scss";
import Header from "../../components/Header";
import Explanation from "../../components/Explanation";
import SignMessage from "./components/SignMessage";
import { I18n } from "i18n-js";
import translations from "../../config/locales.json";

export default function Mission5(props) {
  const i18n = new I18n(translations, props.languageSlug);
  i18n.locale = props.languageSlug;
  return (
    <div className="App">
      <Header languageSlug={props.languageSlug} />
      <div className="wrapper">
        <Explanation languageSlug={props.languageSlug} />

        <br />
        <p>{i18n.t("basic.mission5.description")}</p>
        <a href={i18n.t("basic.mission5.mission-url")}><p>{i18n.t("basic.mission5.title")}</p></a>
        <SignMessage languageSlug={props.languageSlug} />
      </div>
    </div>
  );
}
